





















































import mixins from 'vue-typed-mixins'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
import FlexibleCarousel2 from '@/calendesk/sections/section/shared/components/FlexibleCarousel2.vue'
import FlexibleGallery from '@/calendesk/sections/section/shared/mixins/FlexibleGallery'

export default mixins(FlexibleGallery).extend({
  name: 'FlexibleGallery2',
  components: { DefaultButton, FlexibleCarousel2 }
})
